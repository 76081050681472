import { Link, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useOrder } from "../../../hooks/useOrder";
import {
  Paper,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Badge,
  Grid,
  Button,
  Divider,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Chip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import usePriceCalculator from "../../../components/priceCalculator";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import { useTheme } from "@emotion/react";
import { useAuth } from "../../../hooks/useAuth";
import { BuildCircle } from "@mui/icons-material";

function OrderDetails() {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const { userDetails } = useAuth();
  const { getOrderById, error, loading, updateOrderStatus, downloadOrderPDF } =
    useOrder();
  const [order, setOrder] = useState(null);
  const { calculatePrice, priceInfo } = usePriceCalculator();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const fetchedOrder = await getOrderById(orderId);
        setOrder(fetchedOrder);
      } catch (err) {
        console.error("Error fetching order:", err);
      }
    };

    if (orderId) {
      fetchOrder(); // Ensure this is called only once when orderId changes
    }
  }, [orderId, getOrderById]);

  useEffect(() => {
    if (order?.OrderItems && order?.OrderItems.length > 0) {
      order?.OrderItems.forEach((item) => {
        calculatePrice(
          item.quantity,
          item.Motif.width,
          item.Motif.height,
          item.Motif.colors,
          item.id,
          userDetails.user_id
        );
      });
    }
  }, [order, calculatePrice, userDetails.user_id]);

  const handleStatusChange = async (status) => {
    setAnchorEl(null);
    try {
      await updateOrderStatus(order.id, status);
      setOrder({ ...order, status });
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const handleDownloadPDF = async () => {
    try {
      await downloadOrderPDF(orderId);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case "pending":
        return (
          <Badge
            style={{ padding: "0px 20px" }}
            color="error"
            badgeContent={t("Dashboard.OrderHistory.Status.Pending")}
          />
        );
      case "in-progress":
        return (
          <Badge
            style={{ padding: "0px 20px" }}
            color="info"
            badgeContent={t("Dashboard.OrderHistory.Status.InProgress")}
          />
        );
      case "completed":
        return (
          <Badge
            style={{ padding: "0px 20px" }}
            color="success"
            badgeContent={t("Dashboard.OrderHistory.Status.Completed")}
          />
        );
      case "shipped":
        return (
          <Badge
            style={{ padding: "0px 20px" }}
            color="secondary"
            badgeContent={t("Dashboard.OrderHistory.Status.Shipped")}
          />
        );
      default:
        return (
          <Badge
            style={{ padding: "0px 20px" }}
            color="error"
            badgeContent={t("Dashboard.OrderHistory.Status.Unknown")}
          />
        );
    }
  };

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return (
      <Paper elevation={3} sx={{ padding: 4, margin: "auto" }}>
        <Alert severity="error">{error}</Alert>
      </Paper>
    );
  }

  if (!order) {
    return (
      <Paper elevation={3} sx={{ padding: 4, margin: "auto" }}>
        <Alert severity="warning">No order found.</Alert>
      </Paper>
    );
  }

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
        margin: "auto",
        borderRadius: 2,
      }}
    >
      {/* Order Header with Status */}
      <Grid container spacing={1}>
        <Grid item xs={12} md={10}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4" color="text.light">
              {t("Dashboard.OrderHistory.Order")} /
            </Typography>
            <Typography fontWeight={500} variant="h4" sx={{ ml: 1 }}>
              #{order.id}
            </Typography>
            {getStatusBadge(order.status)}
          </Box>
        </Grid>
        <Grid item xs={12} md={2} textAlign={{ xs: "left", md: "right" }}>
          <Tooltip title={t("Dashboard.OrderHistory.ChangeStatus")} arrow>
            <IconButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              sx={{
                backgroundColor: theme.palette.primary.light,
                borderRadius: 2,
                padding: 1,
                "& .MuiTouchRipple-root span": {
                  borderRadius: 2,
                },
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              sx: {
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
              },
            }}
          >
            <MenuItem onClick={() => handleStatusChange("pending")}>
              <Badge
                sx={{
                  backgroundColor: "error.main",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  mr: 2,
                }}
              />
              {t("Dashboard.OrderHistory.Status.Pending")}
            </MenuItem>
            <MenuItem onClick={() => handleStatusChange("in-progress")}>
              <Badge
                sx={{
                  backgroundColor: "info.main",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  mr: 2,
                }}
              />
              {t("Dashboard.OrderHistory.Status.InProgress")}
            </MenuItem>
            <MenuItem onClick={() => handleStatusChange("completed")}>
              <Badge
                sx={{
                  backgroundColor: "success.main",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  mr: 2,
                }}
              />
              {t("Dashboard.OrderHistory.Status.Completed")}
            </MenuItem>
            <MenuItem onClick={() => handleStatusChange("shipped")}>
              <Badge
                sx={{
                  backgroundColor: "secondary.main",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  mr: 2,
                }}
              />
              {t("Dashboard.OrderHistory.Status.Shipped")}
            </MenuItem>
          </Menu>

          {/* Download PDF Button */}
          <Tooltip title={t("Dashboard.OrderHistory.DownloadPDF")} arrow>
            <IconButton
              onClick={(e) => {
                handleDownloadPDF(order.id);
              }}
              sx={{
                backgroundColor: theme.palette.success.light,
                borderRadius: 2,
                padding: 1,
                "& .MuiTouchRipple-root span": {
                  borderRadius: 2,
                },
                "&:hover": {
                  backgroundColor: theme.palette.success.main,
                },
                ml: 1,
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Left Section (Order Item and Summary) */}
        <Grid item xs={12} md={8}>
          {/* Order Item */}
          <Paper sx={{ boxShadow: "none", padding: 3, mb: 4 }}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="h6">
                {t("Dashboard.OrderHistory.OrderItem")}
              </Typography>
              {order.assembly_included && (
                <Chip
                  icon={<BuildCircle />}
                  label={t("Dashboard.OrderHistory.AssemblyIncluded")}
                  color="warning"
                  sx={{
                    ml: 2,
                    fontWeight: "500",
                    backgroundColor: theme.palette.warning.main, // Use a noticeable color
                    // paddingX: 1.5, // Extra padding for emphasis
                  }}
                />
              )}
            </Box>

            <Divider sx={{ my: 1, mb: 2 }}></Divider>
            {order?.OrderItems.map((item) => {
              // const itemPriceInfo = priceInfo[item.id] || {
              //   pricePerMotif: t("Checkout.Calculating"),
              //   total: t("Checkout.Calculating"),
              // };

              return (
                <Box key={item.id} sx={{ mb: 2 }}>
                  <Link
                    to={`/dashboard/motif/${item.Motif.id}`}
                    state={{ from: "order-details", orderId: order.id }}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <img
                        src={item.Motif.image_url || "/placeholder.png"}
                        alt={item.Motif.name}
                        style={{
                          width: "100%", // Ensures the image fills the container
                          maxWidth: "100px", // Limits the maximum width to 150px
                          height: "auto", // Adjusts the height automatically to maintain aspect ratio
                          maxHeight: "100px", // Limits the maximum height to 150px
                          marginRight: "16px",
                          padding: "8px",
                          backgroundColor: "grey",
                          borderRadius: "5px",
                          objectFit: "contain", // Maintains the aspect ratio within the maxWidth and maxHeight
                        }}
                      />
                      <Box>
                        <Typography variant="subtitle1">
                          {item.Motif.name}
                        </Typography>
                        <Tooltip
                          title={item.Motif.active ? "" : t("Article.Inactive")}
                        >
                          <Paper
                            elevation={0}
                            sx={{
                              backgroundColor: item.Motif.active
                                ? theme.palette.secondary.main
                                : theme.palette.error.main,
                              textAlign: "center",
                              transition: "background-color 0.3s ease",
                              width: "50px", // Fixed width for the box
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              color={item.Motif.active ? "text.main" : "white"}
                              fontSize={10}
                              sx={{ fontWeight: "500", whiteSpace: "nowrap" }} // Prevent wrapping
                            >
                              {item.Motif.article_number}
                            </Typography>
                          </Paper>
                        </Tooltip>
                        <Typography variant="body2">
                          {t("Dashboard.PriceCalculator.Quantity")}:{" "}
                          {item.quantity}
                        </Typography>
                        <Typography variant="body2">
                          {t("Dashboard.Motif.PricePerAbbr")}:{" "}
                          {item.price_per_unit} kr
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" align="right">
                        {t("Dashboard.PriceList.LumpSum")}: +
                        {item.preparation_cost} kr
                      </Typography>
                      <Typography variant="subtitle1" align="right">
                        {t("Dashboard.OrderHistory.Total")}:{" "}
                        {parseFloat(item.total).toFixed(2)} kr
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              );
            })}
            <Divider sx={{ my: 2 }} />
          </Paper>

          {/* Order Summary */}
          <Paper sx={{ boxShadow: "none", padding: 3 }}>
            <Typography variant="h6">
              {t("Dashboard.OrderHistory.OrderSum")}
            </Typography>
            <Divider sx={{ mb: 2 }}></Divider>
            {/* List each order item and its total price */}
            {order?.OrderItems.map((item) => {
              // const itemPriceInfo = priceInfo[item.id] || {
              //   pricePerMotif: t("Checkout.Calculating"),
              //   total: t("Checkout.Calculating"),
              // };

              return (
                <Box
                  key={item.id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Typography>
                    {item.Motif.name} (x{item.quantity})
                  </Typography>
                  <Typography>
                    {parseFloat(item.total).toFixed(2)} kr
                  </Typography>
                </Box>
              );
            })}
            <Divider sx={{ mt: 2 }}></Divider>
            {/* Grand Total */}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
            >
              <Typography variant="h6">
                {t("Dashboard.OrderHistory.Total")}
              </Typography>
              <Typography variant="h6">{order.total_amount} kr</Typography>
            </Box>

            {/* Display Notes Section */}
            {order.notes && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6">
                  {t("Dashboard.OrderHistory.Notes")}
                </Typography>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 1 }}>
                  <Avatar src={order.User.avatar}>
                    {order.shipping_company_name.charAt(0)}
                  </Avatar>
                  <Box sx={{ ml: 1 }} display={"flex"} flexDirection={"column"}>
                    <Typography
                      variant="body2"
                      color="text.light"
                      fontSize={12}
                      sx={{ ml: 1, mb: -0.25 }}
                    >
                      {order.shipping_company_name
                        ? order.shipping_company_name
                        : order.shipping_firstname +
                          " " +
                          order.shipping_lastname}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {order.notes}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Right Section (Customer Details and Shipping Info) */}
        <Grid item xs={12} md={4}>
          {/* Customer Info */}
          <Paper sx={{ boxShadow: "none", padding: 3, mb: 4 }}>
            <Typography variant="h6">
              {t("Dashboard.OrderHistory.Customer")}
            </Typography>
            {order.shipping_company_name && (
              <Typography fontWeight={500}>
                {order.shipping_company_name}
              </Typography>
            )}
            <Typography>
              {order.shipping_firstname} {order.shipping_lastname}
            </Typography>
            <Typography color={"text.light"}>{order.User.email}</Typography>
          </Paper>

          {/* Shipping Info */}
          <Paper sx={{ boxShadow: "none", padding: 3, mb: 4 }}>
            <Typography variant="h6">
              {t("Dashboard.OrderHistory.ShippingAdd")}
            </Typography>
            <Typography>{order.shipping_address}</Typography>
            <Typography>
              {order.shipping_city}, {order.shipping_postal_code}
            </Typography>
            <Typography>{order.shipping_country}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default OrderDetails;
