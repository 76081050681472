import { useState, useCallback } from "react";
import { useAuth } from "../useAuth";
import { API_URL } from "../../apiConfig";
import secureFetch from "../secureFetch";

const discountBaseUrl = `${API_URL}/discount`;

export function useDiscount() {
  const { refreshToken } = useAuth();
  const [discounts, setDiscounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch all discounts
  const getAllDiscounts = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await secureFetch(`${discountBaseUrl}/`, {}, refreshToken);
      setDiscounts(response.data.discounts);
    } catch (error) {
      setError(error.message);
      setDiscounts([]);
    } finally {
      setLoading(false);
    }
  }, [refreshToken]);

  // Fetch discounts by user ID
  const getDiscountByUserId = useCallback(
    async (userId) => {
      setLoading(true);
      setError(null);

      try {
        const response = await secureFetch(`${discountBaseUrl}/${userId}`, {}, refreshToken);
        return response.data.discount;
      } catch (error) {
        setError(error.message);
        return [];
      } finally {
        setLoading(false);
      }
    },
    [refreshToken]
  );

  // Create or update a discount (new name for clarity)
  const createOrUpdateDiscount = useCallback(
    async (discountData) => {
      setLoading(true);
      setError(null);

      try {
        const response = await secureFetch(
          `${discountBaseUrl}`,
          {
            method: "POST", // POST will handle both create and update
            data: discountData,
          },
          refreshToken
        );

        const updatedDiscount = response.data.discount;
        console.log("Discount created or updated:", discountData);

        // Check if discount already exists, and update it or add a new one
        setDiscounts((prevDiscounts) => {
          const discountIndex = prevDiscounts.findIndex(
            (discount) => discount.user_id === updatedDiscount.user_id
          );
          if (discountIndex !== -1) {
            const updatedDiscounts = [...prevDiscounts];
            updatedDiscounts[discountIndex] = updatedDiscount;
            return updatedDiscounts;
          } else {
            return [...prevDiscounts, updatedDiscount];
          }
        });
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [refreshToken]
  );

  // Update a discount by ID
  const updateDiscount = useCallback(
    async (id, discountData) => {
      setLoading(true);
      setError(null);

      try {
        const response = await secureFetch(
          `${discountBaseUrl}/${id}`,
          {
            method: "PUT",
            data: discountData,
          },
          refreshToken
        );

        setDiscounts((prevDiscounts) =>
          prevDiscounts.map((discount) => (discount.id === id ? response.data.discount : discount))
        );
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [refreshToken]
  );

  // Delete a discount by ID
  const deleteDiscount = useCallback(
    async (id) => {
      setLoading(true);
      setError(null);

      try {
        await secureFetch(
          `${discountBaseUrl}/${id}`,
          {
            method: "DELETE",
          },
          refreshToken
        );
        setDiscounts((prevDiscounts) => prevDiscounts.filter((discount) => discount.id !== id));
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [refreshToken]
  );

  return {
    discounts,
    loading,
    error,
    getAllDiscounts,
    getDiscountByUserId,
    createOrUpdateDiscount,
    updateDiscount,
    deleteDiscount,
  };
}
