import React, { useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Container, Grid, Paper, Typography, Avatar, Box, Button, Chip } from "@mui/material";
import { grey } from "@mui/material/colors";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import PercentIcon from "@mui/icons-material/Percent";
import SettingsIcon from "@mui/icons-material/Settings";
import ArticleIcon from "@mui/icons-material/Article";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ViewListIcon from "@mui/icons-material/ViewList";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from '@mui/icons-material/People';
import { useTranslation } from "react-i18next";
import GradingIcon from "@mui/icons-material/Grading";

function Dashboard() {
  const { userDetails, refreshToken, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      navigate("/dashboard/motif");
    }
  }, [location, navigate]);

  useEffect(() => {
    if (!userDetails || Object.keys(userDetails).length === 0) {
      refreshToken().then((isRefreshed) => {
        if (!isRefreshed) {
          logout();
          navigate("/login");
        }
      });
    }
  }, [userDetails, refreshToken, logout, navigate]);

  if (!userDetails || Object.keys(userDetails).length === 0) {
    return null;
  }
  
  const isActive = (path) => location.pathname.includes(path);
  const isAdmin = ["admin", "superadmin"].includes(userDetails.accesslevel);

  const tabs = [
    { icon: <ArticleIcon />, path: "/dashboard/motif", text: t("Dashboard.Motif.DashLabel") },
    // { icon: <InsertChartIcon />, path: "/dashboard/corrections", text: "Mine Korrekturer" },
    {
      icon: <AssignmentIcon />,
      path: "/dashboard/order-history",
      text: t("Dashboard.OrderHistory.Label"),
    },
  ];

  const settingsTabs = [
    {
      icon: <PercentIcon />,
      path: "/dashboard/calculator",
      text: t("Dashboard.PriceCalculator.Label"),
    },
    { icon: <PersonIcon />, path: "/dashboard/profile", text: t("Dashboard.MyProfile.Label") },
    { icon: <SettingsIcon />, path: "/dashboard/settings", text: t("Dashboard.Settings.Label") },
  ];

  const adminTabs = [
    {
      icon: <PriceCheckIcon />,
      path: "/dashboard/admin/price-chart",
      text: t("Dashboard.PriceList.Label"),
      is_admin: true,
    },
    {
      icon: <PersonAddIcon />,
      path: "/dashboard/admin/create-user",
      text: t("Dashboard.CreateUser.Label"),
      is_admin: true,
    },
    {
      icon: <ViewListIcon />,
      path: "/dashboard/admin/all-motifs",
      text: t("Dashboard.AllMotif.Label"),
      is_admin: true,
    },
    {
      icon: <GradingIcon />,
      path: "/dashboard/admin/orders",
      text: t("Dashboard.OrderHistory.Orders"),
      is_admin: true,
    },
    {
      icon: <PeopleIcon />,
      path: "/dashboard/admin/users",
      text: t("Dashboard.Users.Label"),
      is_admin: true,
    },
  ];

  return (
    <Container component="main" maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <Box
            sx={{
              position: "sticky",
              top: 64,
              zIndex: 1,
            }}
          >
            <Paper
              elevation={0}
              sx={{
                padding: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Avatar src={userDetails.avatar} sx={{ mr: 1.5 }} variant="rounded">
                {userDetails.avatar ? "" : userDetails.firstname.charAt(0)}
              </Avatar>
              <Box>
                <Typography component="h6" variant="h6" fontWeight={"500"} mb={isAdmin ? -1 : 0}>
                  {userDetails.firstname || "Name not Loaded"}
                </Typography>
                {isAdmin && (
                  <Chip
                    label={userDetails.accesslevel}
                    size="small"
                    variant="outlined"
                    color="primary"
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: 400,
                      height: 20,
                    }}
                  />
                )}
              </Box>
            </Paper>

            <Paper elevation={0} sx={{ padding: 1 }}>
              {tabs.map((item) => (
                <Button
                  key={item.path}
                  onClick={() => navigate(item.path)}
                  fullWidth
                  sx={{
                    justifyContent: "start",
                    textTransform: "none",
                    color: isActive(item.path) ? "common.white" : "text.secondary",
                    fontWeight: 400,
                    backgroundColor: isActive(item.path) ? "primary.main" : grey[200],
                    "&:hover": {
                      backgroundColor: isActive(item.path) ? "primary.main" : grey[300],
                    },
                  }}
                  startIcon={item.icon}
                >
                  {item.text}
                </Button>
              ))}
            </Paper>
            {isAdmin && (
              <Paper elevation={0} sx={{ padding: 1, marginTop: 1 }}>
                {adminTabs.map((item) => (
                  <Button
                    key={item.path}
                    onClick={() => navigate(item.path)}
                    fullWidth
                    sx={{
                      justifyContent: "start",
                      textTransform: "none",
                      color: isActive(item.path) ? "common.white" : "text.secondary",
                      fontWeight: 400,
                      backgroundColor: isActive(item.path) ? "primary.main" : grey[200],
                      "&:hover": {
                        backgroundColor: isActive(item.path) ? "primary.main" : grey[300],
                      },
                    }}
                    startIcon={item.icon}
                  >
                    {item.text}
                    <Chip
                      label="Admin"
                      size="small"
                      sx={{
                        transition: "all 0.3s ease",
                        backgroundColor: isActive(item.path) ? "background.paper" : "primary.main",
                        color: isActive(item.path) ? "black" : "common.white",
                        ml: 1,
                        height: 20,
                        fontSize: "0.75rem",
                      }}
                    />
                  </Button>
                ))}
              </Paper>
            )}

            <Paper elevation={0} sx={{ padding: 1, marginTop: 1 }}>
              {settingsTabs.map((item) => (
                <Button
                  key={item.path}
                  onClick={() => navigate(item.path)}
                  fullWidth
                  sx={{
                    justifyContent: "start",
                    textTransform: "none",
                    color: isActive(item.path) ? "common.white" : "text.secondary",
                    fontWeight: 400,
                    backgroundColor: isActive(item.path) ? "primary.main" : grey[200],
                    "&:hover": {
                      backgroundColor: isActive(item.path) ? "primary.main" : grey[300],
                    },
                  }}
                  startIcon={item.icon}
                >
                  {item.text}
                </Button>
              ))}
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;
