import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Font,
} from "@react-pdf/renderer";
import { Button, Container } from "@mui/material";
import logo from "../assets/images/ForsteTextLogo2.png";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf",
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});

// PDF component definition
export const OrderPdfTemplate = ({ order }) => {
  // If no order is provided, return placeholder information
  const placeholderOrder = {
    OrderItems: [
      {
        Motif: {
          active: true,
          approved: true,
          article_number: "U3M18",
          colors: 1,
          createdAt: "2022-01-01T00:00:00.000Z",
          height: 80,
          id: 29,
          image_url: "https://placehold.co/500x400.png",
          name: "Placeholder Item 1",
          updatedAt: "2022-01-01T00:00:00.000Z",
          user_id: 3,
          width: 80,
        },
        id: "34",
        motif_id: "34",
        order_id: "23",
        preparation_cost: "100",
        price_per_unit: "100",
        quantity: 1,
        total: "100",
      },
      {
        Motif: {
          active: false,
          approved: true,
          article_number: "U3M18",
          colors: 1,
          createdAt: "2022-01-01T00:00:00.000Z",
          height: 80,
          id: 29,
          image_url: "https://placehold.co/600x400.png",
          name: "Placeholder Item 2",
          updatedAt: "2022-01-01T00:00:00.000Z",
          user_id: 3,
          width: 80,
        },
        id: "34",
        motif_id: "34",
        order_id: "23",
        preparation_cost: "100",
        price_per_unit: "100",
        quantity: 2,
        total: "100",
      },
      {
        Motif: {
          active: false,
          approved: true,
          article_number: "U3M18",
          colors: 1,
          createdAt: "2022-01-01T00:00:00.000Z",
          height: 80,
          id: 29,
          image_url: "https://placehold.co/600x400.png",
          name: "Placeholder Item 2",
          updatedAt: "2022-01-01T00:00:00.000Z",
          user_id: 3,
          width: 80,
        },
        id: "34",
        motif_id: "34",
        order_id: "23",
        preparation_cost: "100",
        price_per_unit: "100",
        quantity: 2,
        total: "100",
      },
    ],
    User: {
      address: "123 Placeholder St",
      avatar: "https://placehold.co/200x200.png",
      city: "Placeholder City",
      company_name: "Placeholder Company",
      country: "NO",
      email: "john.doe@example.com",
      id: "3",
      phone_number: "12345678",
      postal_code: "4038",
      firstname: "john",
      lastname: "doe",
    },
    created_at: new Date(),
    id: "23",
    status: "pending",
    total_amount: "200",
    updated_at: new Date(),
    user_id: "3",
    shipping_address: "123 Placeholder St",
    shipping_city: "Placeholder City",
    shipping_country: "NO",
    shipping_postal_code: "4038",
    shipping_firstname: "john",
    shipping_lastname: "doe",
    shipping_company_name: "Placeholder Company",
    notes:
      "Placeholder notes Placeholder notes Placeholder notes Placeholder notes",
    assembly_included: true,
  };

  const data = order || placeholderOrder; // Use order if available, otherwise use placeholder

  return (
    <Document>
      <Page
        size="A4"
        style={{ padding: "20 40", fontFamily: "Roboto", position: "relative" }}
      >
        {/* Header */}
        <View
          fixed
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 5,
          }}
        >
          {/* Date on the left */}
          <View style={{ textAlign: "left" }}>
            <Text style={{ fontSize: 10, margin: 2, color: "gray" }}>
              {new Date(data.created_at).toLocaleDateString("en-GB")}
            </Text>
          </View>
          {/* Logo and Address on the right */}
          <View>
            <Image src={logo} style={{ width: 280 }} />
          </View>
        </View>

        {/* Main Content */}
        <View style={{ flexGrow: 1, marginBottom: 5 }}>
          {/* Title and Divider */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {/* Ordrebekreftelse on the left */}
            <Text
              style={{ fontSize: 25, fontWeight: "bold", marginBottom: 10 }}
            >
              Ordrebekreftelse
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Text style={{ fontSize: 13 }}>Marviksveien 2B</Text>
              <Text style={{ fontSize: 13, marginBottom: 2 }}>
                4631 Kristiansand , Norge
              </Text>
            </View>
          </View>

          {/* Divider */}
          <View
            style={{
              borderBottomWidth: 2,
              borderBottomColor: "#CDAF04",
              marginVertical: 15,
            }}
          />

          {/* Kunde and Leveringsadresse Section */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {/* Kunde Section */}
            <View style={{ width: "50%" }}>
              <Text
                style={{ fontWeight: "bold", marginBottom: 2, fontSize: 20 }}
              >
                Kunde
              </Text>
              <Text style={{ marginBottom: 2, fontSize: 13 }}>
                {data.shipping_company_name}
              </Text>
              <Text style={{ marginBottom: 2, fontSize: 12 }}>
                Bestilt av: {data.shipping_firstname} {data.shipping_lastname}
              </Text>
            </View>

            {/* Leveringsadresse Section */}
            <View style={{ width: "50%" }}>
              <Text
                style={{ fontWeight: "bold", marginBottom: 2, fontSize: 20 }}
              >
                Leveringsadresse
              </Text>
              <Text style={{ marginBottom: 2, fontSize: 12 }}>
                {data.shipping_address}
              </Text>
              <Text style={{ marginBottom: 2, fontSize: 12 }}>
                {data.shipping_postal_code} {data.shipping_city}
              </Text>
              <Text style={{ marginBottom: 10, fontSize: 12 }}>
                {data.shipping_country}
              </Text>
            </View>
          </View>

          {/* Order Info */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ width: "50%" }}>
              <Text style={{ marginBottom: 2, fontSize: 12 }}>
                Ordredato:{" "}
                {new Date(data.created_at).toLocaleDateString("en-GB")}
              </Text>
            </View>
            <View style={{ width: "50%" }}>
              <Text style={{ marginBottom: 2, fontSize: 12 }}>
                Deres ref: ,{" "}
              </Text>
              <Text style={{ marginBottom: 2, fontSize: 12 }}>
                Vårt ordrenr: {data.id}{" "}
              </Text>
            </View>
          </View>

          {/* Divider */}
          <View
            style={{
              borderBottomWidth: 2,
              borderBottomColor: "#CDAF04",
              marginVertical: 10,
            }}
          />

          <View>
            <Text style={{ marginBottom: 15, fontSize: 12 }}>
              Tusen takk for din bestilling.
            </Text>
            <Text style={{ marginBottom: 15, fontSize: 12 }}>
              Under følger endelige opplysninger om din bestilling. Vi ber om at
              du melder fra om eventuelle endringer så fort som mulig eller
              senest fire timer etter at du mottar denne bekreftelsen.
            </Text>
            <Text style={{ fontSize: 12 }}>
              Forventet leveringstid: 4-5 arbeidsdag(er).
            </Text>
          </View>

          {/* Divider */}
          <View
            style={{
              borderBottomWidth: 2,
              borderBottomColor: "#CDAF04",
              marginVertical: 10,
            }}
          />

          {/* Order Items - Motifs */}
          {data.OrderItems.map((item, index) => (
            <View key={index} style={{}}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "70%" }}>
                  {/* Motif Name and Article Number */}
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        marginBottom: 8,
                      }}
                    >
                      {item.Motif.name}
                    </Text>

                    {/* Article Number with yellow background and rounded corners */}
                    <View
                      style={{
                        backgroundColor: item.Motif.active
                          ? "#FFD700"
                          : "#d32f2f",
                        borderRadius: 4,
                        paddingVertical: 2,
                        paddingHorizontal: 8,
                        marginLeft: 10,
                        alignSelf: "flex-start",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          color: item.Motif.active ? "black" : "white",
                          fontWeight: "bold",
                        }}
                      >
                        {item.Motif.article_number}
                      </Text>
                    </View>
                  </View>

                  {/* Størrelse Row */}
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 5,
                    }}
                  >
                    <Text
                      style={{ fontSize: 12, width: "40%", fontWeight: "bold" }}
                    >
                      Størrelse (LxH):
                    </Text>
                    <Text style={{ fontSize: 12, width: "60%" }}>
                      {item.Motif.width} mm x {item.Motif.height} mm ={" "}
                      {parseFloat(
                        ((item.Motif.width * item.Motif.height) / 100).toFixed(
                          2
                        )
                      )}{" "}
                      cm²
                    </Text>
                  </View>

                  {/* Ant. Farger Row */}
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 5,
                    }}
                  >
                    <Text
                      style={{ fontSize: 12, width: "40%", fontWeight: "bold" }}
                    >
                      Ant. Farger:
                    </Text>
                    <Text style={{ fontSize: 12, width: "60%" }}>
                      {item.Motif.colors}
                    </Text>
                  </View>

                  {/* Antall Row */}
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 5,
                    }}
                  >
                    <Text
                      style={{ fontSize: 12, width: "40%", fontWeight: "bold" }}
                    >
                      Antall:
                    </Text>
                    <Text style={{ fontSize: 12, width: "60%" }}>
                      {item.quantity}
                    </Text>
                  </View>

                  {/* Pris pr. stk. Row */}
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 5,
                    }}
                  >
                    <Text
                      style={{ fontSize: 12, width: "40%", fontWeight: "bold" }}
                    >
                      Pris pr. stk.:
                    </Text>
                    <Text style={{ fontSize: 12, width: "60%" }}>
                      NOK {item.price_per_unit}
                    </Text>
                  </View>

                  {/* Klargjøring Row */}
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 5,
                    }}
                  >
                    <Text
                      style={{ fontSize: 12, width: "40%", fontWeight: "bold" }}
                    >
                      Klargjøring:
                    </Text>
                    <Text style={{ fontSize: 12, width: "60%" }}>
                      NOK {item.preparation_cost}
                    </Text>
                  </View>

                  {/* Totalpris Row */}
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 5,
                    }}
                  >
                    <Text
                      style={{ fontSize: 12, fontWeight: "bold", width: "40%" }}
                    >
                      Totalpris:
                    </Text>
                    <Text style={{ fontSize: 12, width: "60%" }}>
                      NOK {item.total}
                    </Text>
                  </View>
                </View>

                {/* Image */}
                <View style={{ width: "30%", textAlign: "right" }}>
                  <Image
                    src={item.Motif.image_url}
                    style={{
                      width: 120,
                      aspectRatio: "auto",
                      backgroundColor: "gray",
                      padding: 5,
                    }}
                  />
                </View>
              </View>

              {/* Divider */}
              <View
                style={{
                  borderBottomWidth: 2,
                  borderBottomColor: "#CDAF04",
                  marginVertical: 5,
                }}
              />
            </View>
          ))}
          {/* Total Summary */}
          <View style={{}}>
            <Text style={{ fontWeight: "bold", fontSize: 12 }}>Total</Text>
            {data.assembly_included && (
              <Text
                style={{ fontWeight: "bold", fontSize: 10, color: "#3158eb" }}
              >
                (Montering Inkludert)
              </Text>
            )}
            <View
              style={{
                borderBottomWidth: 1,
                borderBottomColor: "#CDAF04",
                marginVertical: 3,
              }}
            />
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "50%" }}>
                {data.OrderItems.map((item, index) => (
                  <Text key={index} style={{ fontSize: 12, marginVertical: 3 }}>
                    {item.Motif.name}
                  </Text>
                ))}
              </View>
              <View style={{ width: "50%", textAlign: "right" }}>
                {data.OrderItems.map((item, index) => (
                  <Text key={index} style={{ fontSize: 12, marginVertical: 3 }}>
                    NOK {item.total}
                  </Text>
                ))}
              </View>
            </View>
            <View
              style={{
                borderBottomWidth: 1,
                borderBottomColor: "#CDAF04",
                marginVertical: 3,
              }}
            />

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 2,
              }}
            >
              <Text style={{ fontWeight: "bold", fontSize: 12 }}>Sum</Text>
              <Text style={{ fontWeight: "bold", fontSize: 12 }}>
                NOK {data.total_amount}
              </Text>
            </View>
          </View>

          {/* Divider */}
          <View
            style={{
              borderBottomWidth: 2,
              borderBottomColor: "#CDAF04",
              marginVertical: 7,
            }}
          />
          <View>
            <Text style={{ fontSize: 12 }}>
              Alle priser er oppgitt eks. mva.
            </Text>
          </View>

          {/* Divider */}
          <View
            style={{
              borderBottomWidth: 2,
              borderBottomColor: "#CDAF04",
              marginVertical: 7,
            }}
          />

          {/* Notes Section */}
          {data.notes && (
            <View style={{ marginTop: 10 }}>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginBottom: 3,
                }}
              >
                Kunde Kommentar
              </Text>
              {/* Divider */}

              <Text style={{ fontSize: 12 }}>{data.notes}</Text>
              {/* Divider */}
              <View
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "#CDAF04",
                  marginVertical: 5,
                }}
              />
            </View>
          )}
        </View>

        {/* Footer */}
        <View
          fixed
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingTop: 15,
            bottom: 0,
          }}
        >
          <Image src={logo} style={{ left: -36, width: 290, maxHeight: 40 }} />
          <View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginBottom: 10,
              }}
            >
              <Text style={{ fontSize: 13 }}>Marviksveien 2B</Text>
              <Text style={{ fontSize: 13, marginBottom: 2 }}>
                4631 Kristiansand , Norge
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Text style={{ fontSize: 13, marginBottom: 2 }}>
                TELEFON: 474366224
              </Text>
              <Text style={{ fontSize: 13, marginBottom: 2 }}>
                E-post: post@forsteinntrykk.no
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const PDFViewPage = () => {
  return (
    <Container>
      <PDFDownloadLink fileName="OrderTest" document={<OrderPdfTemplate />}>
        <Button variant="contained" color="success">
          Download PDF
        </Button>
      </PDFDownloadLink>

      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <OrderPdfTemplate />
      </PDFViewer>
    </Container>
  );
};

export default PDFViewPage;
