import { useState } from "react";
import { pdf } from "@react-pdf/renderer";
import { OrderPdfTemplate } from "../components/OrderPDFTemplate";
import secureFetch from "./secureFetch";
import { API_URL } from "../apiConfig";
import { useAuth } from "./useAuth";

const baseUrl = `${API_URL}/send-email`;

// Hook to handle sending email with order PDF
export function useEmail() {
  const { refreshToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Generate the PDF on the frontend
  const generatePdfBlob = async (order) => {
    const doc = <OrderPdfTemplate order={order} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc); // Update the PDF container
    const blob = await asPdf.toBlob();

    // Convert Blob to Base64
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Remove metadata
        resolve(base64String);
      };
    });
  };

  // Send the email with the order PDF attached
  const sendEmail = async (order) => {
    setLoading(true);
    setError(null);

    try {
      const pdfBase64 = await generatePdfBlob(order);

      // Send to the serverless function
      const response = await secureFetch(
        `${baseUrl}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: order.User.email,
            pdf: pdfBase64,
            orderId: order.id,
          }),
        },
        refreshToken
      );

      if (!response.ok) {
        throw new Error("Failed to send email");
      }
      
      console.log("Email sent successfully");
    } catch (err) {
      console.error(err);
      setError(err.message || "Failed to send email");
    } finally {
      setLoading(false);
    }
  };

  return { sendEmail, loading, error };
}
