import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
  TextField,
  Box,
  Grid,
  Menu,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Chip,
  InputAdornment,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useUser } from "../../../../hooks/useUser";
import { useDiscount } from "../../../../hooks/Price/useDiscount"; // Import the useDiscount hook
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import DiscountIcon from "@mui/icons-material/Discount";
import { Edit } from "@mui/icons-material";

function UsersPage() {
  const { t } = useTranslation();
  const { users, loading, error, getAllUsers, removeUser } = useUser();
  const { discounts, getAllDiscounts, createOrUpdateDiscount, updateDiscount } =
    useDiscount(); // Get all discounts from useDiscount
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDiscountDialog, setOpenDiscountDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [discountValues, setDiscountValues] = useState({
    color_discount: 0,
    lump_sum_discount: 0,
    general_discount: 0,
    assembly_discount: 0,
  });

  useEffect(() => {
    getAllUsers();
    getAllDiscounts();
  }, [getAllUsers, getAllDiscounts]);

  useEffect(() => {
    if (users) {
      setFilteredUsers(users);
    }
  }, [users]);

  const handleRemoveUser = (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      removeUser(userId);
    }
  };

  const getUserDiscounts = (userId) => {
    return discounts.filter((discount) => discount.user_id === userId);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    const searchValue = event.target.value.toLowerCase();

    const filtered = users.filter(
      (user) =>
        (user.firstname?.toLowerCase() || "").includes(searchValue) ||
        (user.lastname?.toLowerCase() || "").includes(searchValue) ||
        (user.email?.toLowerCase() || "").includes(searchValue) ||
        (user.company_name?.toLowerCase() || "").includes(searchValue)
    );

    setFilteredUsers(filtered);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDiscountDialog = (user) => {
    const userDiscounts = getUserDiscounts(user.id);
    const colorDiscount =
      userDiscounts.find((d) => d.color_discount)?.color_discount || 0;
    const lumpSumDiscount =
      userDiscounts.find((d) => d.lump_sum_discount)?.lump_sum_discount || 0;
    const generalDiscount =
      userDiscounts.find((d) => d.general_discount)?.general_discount || 0;
    const assemblyDiscount =
      userDiscounts.find((d) => d.assembly_discount)?.assembly_discount || 0;

    setDiscountValues({
      color_discount: colorDiscount,
      lump_sum_discount: lumpSumDiscount,
      general_discount: generalDiscount,
      assembly_discount: assemblyDiscount,
    });

    setSelectedUser(user);
    setOpenDiscountDialog(true);
  };

  const handleCloseDiscountDialog = () => {
    setOpenDiscountDialog(false);
    setSelectedUser(null);
  };

  const handleDiscountChange = (field) => (e) => {
    setDiscountValues((prev) => ({
      ...prev,
      [field]: parseFloat(e.target.value) || 0,
    }));
  };

  const handleSubmitDiscount = async () => {
    if (!selectedUser) return;

    try {
      // Call createOrUpdateDiscount to handle both creation and updating
      console.log("Discount submitted:", discountValues);
      await createOrUpdateDiscount({
        user_id: selectedUser.id,
        color_discount: discountValues.color_discount,
        lump_sum_discount: discountValues.lump_sum_discount,
        general_discount: discountValues.general_discount,
        assembly_discount: discountValues.assembly_discount,
      });

      handleCloseDiscountDialog(); // Close the dialog after submitting
    } catch (error) {
      console.error("Error saving discount:", error);
    }
  };

  const getRoleBadge = (role) => {
    switch (role) {
      case "admin":
        return (
          <Chip
            size="small"
            label="Admin"
            sx={{
              backgroundColor: "#FFD700",
              color: "black",
              fontWeight: "500",
              fontSize: 10,
            }}
          />
        );
      case "superadmin":
        return (
          <Chip
            size="small"
            label="Super Admin"
            sx={{
              backgroundColor: "primary.dark",
              color: "white",
              fontWeight: "500",
              fontSize: 10,
            }}
          />
        );
      default:
        return (
          <Chip
            size="small"
            label="Customer"
            sx={{
              backgroundColor: "secondary.main",
              color: "black",
              fontWeight: "500",
              fontSize: 10,
            }}
          />
        );
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const options = { day: "numeric", month: "long" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const formatTime = (dateString) => {
    if (!dateString) return "-";
    const options = { hour: "2-digit", minute: "2-digit" };
    return new Date(dateString).toLocaleTimeString("en-GB", options);
  };

  const formatDiscount = (value) => {
    return parseFloat(value).toLocaleString(undefined, {
      minimumFractionDigits: value % 1 === 0 ? 0 : 1,
      maximumFractionDigits: 1,
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          margin: "auto",
          borderRadius: 2,
        }}
      >
        {/* User Search and Filters */}
        <Grid container spacing={2} alignItems="center" marginBottom={2}>
          <Grid item xs={12} md={5}>
            <Typography variant="h4" gutterBottom>
              {t("Dashboard.Users.Label")}
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <Box display="flex" alignItems="center" width="100%">
              <TextField
                fullWidth
                placeholder={t("Dashboard.Users.Search")}
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ mr: 1 }}
              />
              <IconButton onClick={handleOpenMenu}>
                <FilterListIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={() => handleCloseMenu()}>
                  Filter Option 1
                </MenuItem>
                <MenuItem onClick={() => handleCloseMenu()}>
                  Filter Option 2
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>

        {/* User Cards */}
        {filteredUsers.length === 0 ? (
          <Typography>{t("Dashboard.Users.NoUsers")}</Typography>
        ) : (
          <Grid container spacing={2}>
            {filteredUsers.map((user) => (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={user.id}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        position: "relative",
                        pb: 0,
                      }}
                    >
                      {/* Edit Button at the top right */}
                      <Tooltip title={t("Dashboard.Users.Edit")} arrow>
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: 5,
                            right: 5,
                            backgroundColor: "secondary.main",
                            borderRadius: 2,
                            padding: 1,
                            zIndex: 10, // Ensure it's above other elements
                            pointerEvents: "auto", // Ensure the button can be interacted with
                            "&:hover": {
                              backgroundColor: "secondary.dark",
                            },
                          }}
                        >
                          <Edit sx={{ fontSize: 15 }} />
                        </IconButton>
                      </Tooltip>

                      <Box
                        backgroundColor="primary.main"
                        sx={{
                          width: "100%",
                          height: "60px",
                        }}
                      />

                      {/* Avatar and Badge inside the same container */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          position: "relative",
                          marginTop: "-40px",
                        }}
                      >
                        <Avatar
                          src={user.avatar}
                          alt={user.firstname}
                          variant="rounded"
                          sx={{
                            backgroundColor: "#bdbdbd",
                            border: "2px solid #fff",
                            width: 56,
                            height: 56,
                          }}
                        >
                          {user.avatar ? "" : user.firstname.charAt(0)}
                        </Avatar>

                        {/* Access Level Badge next to the avatar */}
                        <Box
                          sx={{
                            position: "absolute",
                            top: -14,
                            left: 8,
                          }}
                        >
                          {getRoleBadge(user.accesslevel)}
                        </Box>

                        <Box textAlign={"center"} mt={0}>
                          <Typography variant="body1" fontWeight={500}>
                            {user.firstname} {user.lastname}
                          </Typography>

                          {user.company_name && (
                            <Typography
                              variant="body2"
                              fontWeight={500}
                              color="primary.dark"
                              marginTop={-1}
                            >
                              {user.company_name}
                            </Typography>
                          )}

                          <Typography
                            variant="body2"
                            fontSize={"0.8rem"}
                            marginTop={-0.7}
                            color="textSecondary"
                          >
                            {user.email}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <CardContent sx={{ padding: "5px 8px" }}>
                      <Typography
                        variant="subtitle2"
                        fontSize={"1rem"}
                        color="textSecondary"
                      >
                        {t("Dashboard.MyProfile.Address")}
                      </Typography>
                      <Typography mt={-0.8}>{user.address || "-"}</Typography>
                      <Typography mt={-0.8}>
                        {user.city && user.postal_code ? (
                          <>
                            {user.city}, {user.postal_code}
                          </>
                        ) : user.city ? (
                          user.city
                        ) : user.postal_code ? (
                          user.postal_code
                        ) : (
                          ""
                        )}
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        fontSize={"1rem"}
                        color="textSecondary"
                        mt={2}
                      >
                        {t("Dashboard.Users.LastLogin")}
                      </Typography>
                      <Tooltip
                        title={formatTime(user.last_login)}
                        arrow
                        placement="bottom-start"
                      >
                        <Typography mt={-0.8}>
                          {formatDate(user.last_login)}
                        </Typography>
                      </Tooltip>
                    </CardContent>
                  </Box>

                  <Box>
                    <Divider variant="middle" sx={{ marginY: 1 }} />
                    <CardActions
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 1,
                        padding: "5px 8px",
                        "& > :not(style)~:not(style)": { marginLeft: 0 },
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                        mb={0}
                      >
                        <Typography
                          variant="subtitle2"
                          fontSize={"1rem"}
                          color="textSecondary"
                        >
                          {t("Dashboard.Users.Discount")}
                        </Typography>
                        <Tooltip
                          title={t("Dashboard.Users.GiveDiscount")}
                          arrow
                        >
                          <IconButton
                            onClick={() => handleOpenDiscountDialog(user)}
                            sx={{
                              backgroundColor: "primary.light",
                              borderRadius: 2,
                              padding: 1,
                              "&:hover": {
                                backgroundColor: "primary.main",
                              },
                            }}
                          >
                            <DiscountIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      {/* Discounts Display in a Grid */}
                      <Box
                        display="flex"
                        gap={1}
                        flexWrap="wrap"
                        justifyContent="flex-start"
                        sx={{
                          width: "100%",
                          "& .MuiTextField-root": {
                            minWidth: "70px", // Ensure consistent sizing
                            flexGrow: 1, // Allow text fields to grow if space is available
                          },
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          {getUserDiscounts(user.id).map((discount) => {
                            const discountStyles = {
                              width: "100%",
                              "& .MuiInputBase-root": {
                                fontSize: "0.8rem", // Smaller font size inside the input
                                padding: "0px 0px", // Minimize padding inside the textfield
                              },
                              "& .MuiInputLabel-root": {
                                fontSize: "0.7rem", // Smaller label text
                                transform: "translate(12px, -7px) scale(1)", // Adjust label positioning
                              },
                              "& .MuiOutlinedInput-root": {
                                paddingRight: "10px", // Reduce padding on the right
                                paddingLeft: "0px", // Reduce padding on the left
                              },
                            };

                            const discountCount = [
                              discount.color_discount > 0,
                              discount.lump_sum_discount > 0,
                              discount.general_discount > 0,
                              discount.assembly_discount > 0,
                            ].filter(Boolean).length;

                            const gridSize =
                              discountCount === 1
                                ? 12
                                : discountCount === 2
                                ? 6
                                : discountCount === 3
                                ? 4
                                : 6;

                            return (
                              <React.Fragment key={discount.id}>
                                {discount.color_discount > 0 && (
                                  <Grid item xs={4} md={gridSize}>
                                    <TextField
                                      sx={discountStyles}
                                      value={formatDiscount(
                                        discount.color_discount
                                      )}
                                      variant="outlined"
                                      size="small"
                                      disabled
                                      InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            %
                                          </InputAdornment>
                                        ),
                                      }}
                                      label={t("Dashboard.Users.Color")}
                                    />
                                  </Grid>
                                )}
                                {discount.lump_sum_discount > 0 && (
                                  <Grid item xs={4} md={gridSize}>
                                    <TextField
                                      sx={discountStyles}
                                      value={formatDiscount(
                                        discount.lump_sum_discount
                                      )}
                                      variant="outlined"
                                      size="small"
                                      disabled
                                      InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            %
                                          </InputAdornment>
                                        ),
                                      }}
                                      label={t("Dashboard.Users.LumpSum")}
                                    />
                                  </Grid>
                                )}
                                {discount.general_discount > 0 && (
                                  <Grid item xs={4} md={gridSize}>
                                    <TextField
                                      sx={discountStyles}
                                      value={formatDiscount(
                                        discount.general_discount
                                      )}
                                      variant="outlined"
                                      size="small"
                                      disabled
                                      InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            %
                                          </InputAdornment>
                                        ),
                                      }}
                                      label={t("Dashboard.Users.Price")}
                                    />
                                  </Grid>
                                )}
                                {discount.assembly_discount > 0 && (
                                  <Grid item xs={4} md={gridSize}>
                                    <TextField
                                      sx={discountStyles}
                                      value={formatDiscount(
                                        discount.assembly_discount
                                      )}
                                      variant="outlined"
                                      size="small"
                                      disabled
                                      InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            %
                                          </InputAdornment>
                                        ),
                                      }}
                                      label={t("Dashboard.Users.Assembly")}
                                    />
                                  </Grid>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </Grid>
                      </Box>
                    </CardActions>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>
      {/* Discount Dialog */}
      <Dialog open={openDiscountDialog} onClose={handleCloseDiscountDialog}>
        <DialogTitle>{t("Dashboard.Users.GiveDiscount")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Dashboard.Users.AssignDiscount")}
          </DialogContentText>
          <TextField
            margin="dense"
            label={t("Dashboard.Users.ColorDiscount")}
            type="number"
            fullWidth
            variant="outlined"
            value={discountValues.color_discount}
            onChange={handleDiscountChange("color_discount")}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <TextField
            margin="dense"
            label={t("Dashboard.Users.LumpSumDiscount")}
            type="number"
            fullWidth
            variant="outlined"
            value={discountValues.lump_sum_discount}
            onChange={handleDiscountChange("lump_sum_discount")}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <TextField
            margin="dense"
            label={t("Dashboard.Users.GeneralDiscount")}
            type="number"
            fullWidth
            variant="outlined"
            value={discountValues.general_discount}
            onChange={handleDiscountChange("general_discount")}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <TextField
            margin="dense"
            label={t("Dashboard.Users.AssemblyDiscount")}
            type="number"
            fullWidth
            variant="outlined"
            value={discountValues.assembly_discount}
            onChange={handleDiscountChange("assembly_discount")}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDiscountDialog} color="secondary">
            {t("Dashboard.Users.Cancel")}
          </Button>
          <Button onClick={handleSubmitDiscount} color="primary">
            {t("Dashboard.Users.Submit")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UsersPage;
