import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
  Avatar,
  Tabs,
  Tab,
  Badge,
} from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { useUser } from "../../../hooks/useUser";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
import AvatarUpload from "../../../components/admin/AvatarUpload";

const BannerContainer = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
}));

const Banner = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "150px",
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(1, 1, 0, 0),
}));

const ProfileDetailsContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
}));

const AvatarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  marginTop: theme.spacing(-8),
  marginBottom: theme.spacing(2),
}));

const libraries = ["places"];

const ProfilePage = () => {
  const { userDetails, refreshToken } = useAuth();
  const { t } = useTranslation();
  const { user, loading, error, getUserByID, updateUserProfile } = useUser();
  const [profile, setProfile] = useState({
    firstname: "",
    lastname: "",
    company_name: "",
    address: "",
    city: "",
    country: "",
    postal_code: "",
    phone_number: "",
    email: "",
    avatar: "",
  });
  const [success, setSuccess] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const inputRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [avatarFile, setAvatarFile] = useState("");
  const [avatarPreview, setAvatarPreview] = useState("");

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_API_KEY,
    libraries: libraries,
  });

  useEffect(() => {
    if (userDetails && userDetails.user_id) {
      getUserByID(userDetails.user_id);
    }
  }, [userDetails, getUserByID]);

  useEffect(() => {
    if (user) {
      console.log(user); // Debug: log the user object
      setProfile({
        firstname: user.firstname || "",
        lastname: user.lastname || "",
        company_name: user.company_name || "",
        address: user.address || "",
        city: user.city || "",
        country: user.country || "",
        postal_code: user.postal_code || "",
        phone_number: user.phone_number || "",
        email: user.email || "",
      });
    }
  }, [user]);

  const handleChange = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);

    try {
      const formData = new FormData();
      formData.append("firstname", profile.firstname);
      formData.append("lastname", profile.lastname);
      formData.append("company_name", profile.company_name);
      formData.append("address", profile.address);
      formData.append("city", profile.city);
      formData.append("country", profile.country);
      formData.append("postal_code", profile.postal_code);
      formData.append("phone_number", profile.phone_number);

      // Ensure avatarFile is set before appending
      if (avatarFile) {
        formData.append("avatar", avatarFile); // This field name must match the backend configuration
      }

      await updateUserProfile(userDetails.user_id, formData); // Use FormData to send the request
      await refreshToken();
      window.location.reload();
      setSuccess(true);
    } catch (error) {
      console.error("Failed to update profile", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAvatarSave = (canvas, imgName) => {
    const avatar = canvas.toDataURL();
    canvas.toBlob((blob) => {
      if (!blob) {
        console.error("Canvas is empty");
        return;
      }

      const file = new File([blob], imgName, {
        type: "image/png",
        lastModified: new Date(),
      });
      
      setAvatarFile(file);
      setAvatarPreview(avatar);

      // Optionally, you can also update the avatar in the backend here
      setModalOpen(false);
    }, "image/png");
  };

  // Function to handle place selection and autofill the form fields
  const handleOnPlacesChanged = () => {
    const places = inputRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0]; // Get the first place
      const addressComponents = place.address_components;

      const getComponent = (components, type, useShortName = false) => {
        const component = components.find((comp) => comp.types.includes(type));
        return component ? (useShortName ? component.short_name : component.long_name) : "";
      };

      // Autofill the address fields
      setProfile((prev) => ({
        ...prev,
        address:
          getComponent(addressComponents, "route") +
          " " +
          getComponent(addressComponents, "street_number"),
        city:
          getComponent(addressComponents, "locality") ||
          getComponent(addressComponents, "postal_town"),
        postal_code: getComponent(addressComponents, "postal_code"),
        country: getComponent(addressComponents, "country"),
      }));
    }
  };

  // const handleCountrySelect = (countryCode) => {
  //   setProfile((prev) => ({
  //     ...prev,
  //     country: countryCode,
  //   }));
  // };

  if (loading) return <CircularProgress />;
  if (loadError) return <Alert severity="error">{t("Dashboard.MyProfile.LoadError")}</Alert>;

  return (
    <Container component="main" sx={{ px: { xs: 0 } }}>
      <BannerContainer elevation={3}>
        <Banner />
        <AvatarContainer>
          <Badge
            onClick={() => setModalOpen(true)} // Open the modal on click
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            overlap="rectangular"
            sx={{cursor: "pointer"}}
            badgeContent={
              <PhotoCamera
                sx={{
                  color: "black",
                  padding: 0.5,
                  backgroundColor: "secondary.main",
                  borderRadius: "100%",
                }}
              />
            }
          >
            <Avatar
              src={avatarPreview || userDetails.avatar}
              alt={userDetails?.firstname || "User Avatar"}
              variant="rounded"
              sx={{
                width: 128,
                height: 128,
                mb: 2,
                border: "3px dashed",
                borderColor: "secondary.main",
              }}
            >
              {avatarPreview ? "" : userDetails.firstname.charAt(0)}
            </Avatar>
          </Badge>
          <Typography variant="h5">{userDetails?.firstname}</Typography>
          <Typography color={"text.secondary"} variant="body">
            {userDetails?.email}
          </Typography>
        </AvatarContainer>
      </BannerContainer>

      <ProfileDetailsContainer elevation={3}>
        <Tabs value={tabValue} onChange={handleTabChange} centered sx={{ mb: 4 }}>
          <Tab label={t("Dashboard.MyProfile.PersonalInfo")} />
          <Tab label={t("Dashboard.MyProfile.AccountSettings")} />
        </Tabs>

        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{t("Dashboard.MyProfile.Success")}</Alert>}

        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {tabValue === 0 && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("Dashboard.MyProfile.FirstName")}
                    name="firstname"
                    value={profile.firstname}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("Dashboard.MyProfile.LastName")}
                    name="lastname"
                    value={profile.lastname}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("Dashboard.MyProfile.Company")}
                    name="company_name"
                    value={profile.company_name}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  {isLoaded && (
                    <StandaloneSearchBox
                      onLoad={(ref) => (inputRef.current = ref)}
                      onPlacesChanged={handleOnPlacesChanged}
                    >
                      <TextField
                        label={t("Dashboard.MyProfile.Address")}
                        name="address"
                        value={profile.address}
                        onChange={handleChange}
                        fullWidth
                        rows={2}
                      />
                    </StandaloneSearchBox>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("Dashboard.MyProfile.City")}
                    name="city"
                    value={profile.city}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <ReactFlagsSelect
                    selected={profile.country}
                    onSelect={handleCountrySelect}
                    searchable
                    fullWidth
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("Dashboard.MyProfile.PostalCode")}
                    name="postal_code"
                    value={profile.postal_code}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("Dashboard.MyProfile.PhoneNumber")}
                    name="phone_number"
                    value={profile.phone_number}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t("Dashboard.MyProfile.Email")}
                    name="email"
                    value={profile.email}
                    onChange={handleChange}
                    fullWidth
                    disabled
                  />
                </Grid>
              </>
            )}

            {tabValue === 1 && (
              <Grid item xs={12}>
                <Typography variant="h6">{t("Dashboard.MyProfile.AccountSettings")}</Typography>
                {/* Add account settings form fields here */}
              </Grid>
            )}
          </Grid>
          {tabValue === 0 && (
            <Box sx={{ mt: 3, textAlign: "right" }}>
              <Button type="submit" variant="contained" color="primary">
                {t("Dashboard.MyProfile.Submit")}
              </Button>
            </Box>
          )}
        </Box>
      </ProfileDetailsContainer>
      {modalOpen && (
        <AvatarUpload
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleAvatarSave}
        />
      )}
    </Container>
  );
};

export default ProfilePage;
