import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  IconButton,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as LinkIcon, LinkOff as LinkOffIcon } from "@mui/icons-material";
import { useMotifs } from "../../../hooks/useMotifs";
import { useTheme } from "@emotion/react";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

function EditMotif() {
  const navigate = useNavigate();
  const { motifId } = useParams();
  const { userDetails } = useAuth();
  const theme = useTheme();
  const isAdminOrSuperAdmin = ["admin", "superadmin"].includes(userDetails?.accesslevel);
  const { getMotifById, updateMotif, deleteMotif, loading, error } = useMotifs();
  const { t } = useTranslation();

  const [motif, setMotif] = useState({
    name: "",
    width: "",
    height: "",
    area: "",
    colors: 1,
    imageUrl: "",
  });
  const [locked, setLocked] = useState(true);
  const [openDelDia, setOpenDelDia] = useState(false);
  const [openImgDia, setOpenImgDia] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(motif.imageUrl);

  useEffect(() => {
    const fetchMotif = async () => {
      const data = await getMotifById(motifId);
      if (data) {
        const calculatedArea = (data.width * data.height) / 100;
        const aspectRatio = data.width / data.height;
        setMotif({ ...data, area: calculatedArea, aspectRatio });
      }
    };
    fetchMotif();
  }, [motifId, getMotifById]);

  const handleChange = (prop) => (event) => {
    const value =
      prop === "colors"
        ? parseInt(event.target.value, 10) || 1
        : prop === "name"
        ? event.target.value
        : parseFloat(event.target.value) || 0;

    setMotif((prevState) => {
      let newWidth = prevState.width;
      let newHeight = prevState.height;

      if (prop === "width" || prop === "height") {
        if (prop === "width") {
          newWidth = value;
          if (locked) newHeight = Math.round(value / prevState.aspectRatio);
        } else if (prop === "height") {
          newHeight = value;
          if (locked) newWidth = Math.round(value * prevState.aspectRatio);
        }
        const newArea = (newWidth * newHeight) / 100;
        return { ...prevState, [prop]: value, width: newWidth, height: newHeight, area: newArea };
      }
      return { ...prevState, [prop]: value };
    });
  };

  const toggleAspectRatioLock = () => {
    setLocked(!locked);
    if (!locked) {
      setMotif((prev) => ({
        ...prev,
        height: Math.round(prev.width / prev.aspectRatio),
        area: (prev.width * Math.round(prev.width / prev.aspectRatio)) / 100,
      }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUploadConfirm = () => {
    setMotif((prevMotif) => ({ ...prevMotif, imageUrl: imagePreviewUrl }));
    diaImgClose();
  };

  const handleUpdate = async () => {
    let updatedMotif;
    if (newImage) {
      updatedMotif = await updateMotif(motifId, motif, newImage);
    } else {
      updatedMotif = await updateMotif(motifId, motif);
    }

    if (updatedMotif && updatedMotif.id) {
      navigate(`/dashboard/motif/${updatedMotif.id}`, { state: { from: "motif-list" } });
    }
  };

  const handleDownload = (url, name) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = name || "image";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const diaDeleteOpen = () => {
    setOpenDelDia(true);
  };

  const diaDeleteClose = () => {
    setOpenDelDia(false);
  };

  const diaImgOpen = () => {
    setOpenImgDia(true);
  };

  const diaImgClose = () => {
    setOpenImgDia(false);
  };

  const confirmDelete = async () => {
    await deleteMotif(motifId);
    navigate("/dashboard/motif");
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Paper elevation={3} sx={{ p: 3, mx: "auto" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <img
            src={motif.imageUrl || "https://via.placeholder.com/500x300?text=No+Image"}
            alt={motif.name}
            style={{
              width: "100%",
              padding: "2.5%",
              height: "auto",
              borderRadius: "8px",
              backgroundColor: "gray",
            }}
          />
          <Box sx={{ mt: 1, display: "flex", justifyContent: "center", gap: 1 }}>
            <Tooltip title={t("Dashboard.Motif.TipDownload")} arrow>
              <IconButton
                onClick={() => handleDownload(motif.imageUrl, motif.name)}
                sx={{
                  backgroundColor: theme.palette.grey[400],
                  borderRadius: 2,
                  padding: 1,
                  "& .MuiTouchRipple-root span": {
                    borderRadius: 2,
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.grey[500],
                  },
                }}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
            {isAdminOrSuperAdmin && (
              <Tooltip title={t("Dashboard.Motif.TipNewImg")} arrow>
                <IconButton
                  onClick={diaImgOpen}
                  sx={{
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: 2,
                    padding: 1,
                    "& .MuiTouchRipple-root span": {
                      borderRadius: 2,
                    },
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.dark,
                    },
                  }}
                >
                  <FileUploadIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={t("Dashboard.Motif.TipDel")} arrow>
              <IconButton
                aria-label="delete"
                onClick={diaDeleteOpen}
                sx={{
                  backgroundColor: theme.palette.error.light,
                  borderRadius: 2,
                  padding: 1,
                  "& .MuiTouchRipple-root span": {
                    borderRadius: 2,
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.error.main,
                  },
                }}
              >
                <DeleteIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={9}>
              <Typography variant="h5" sx={{ fontWeight: "500" }}>
                {t("Dashboard.Motif.EditMotif")}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: "right" }}>
              <Tooltip title={t("Dashboard.Motif.GoBack")} arrow>
                <IconButton aria-label="back" onClick={() => navigate(-1)}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <TextField
            fullWidth
            label={t("Dashboard.Motif.Name")}
            value={motif.name}
            onChange={handleChange("name")}
            margin="normal"
          />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <TextField
                fullWidth
                label={t("Dashboard.Motif.Width")}
                type="number"
                value={motif.width}
                onChange={handleChange("width")}
                margin="normal"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={2} sx={{ textAlign: "center" }}>
              <IconButton color="primary" onClick={toggleAspectRatioLock}>
                {locked ? <LinkIcon /> : <LinkOffIcon />}
              </IconButton>
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label={t("Dashboard.Motif.Height")}
                type="number"
                value={motif.height}
                onChange={handleChange("height")}
                margin="normal"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("Dashboard.Motif.Colors")}
                type="number"
                value={motif.colors}
                onChange={handleChange("colors")}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{t("Dashboard.Motif.Color")}</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("Dashboard.Motif.Area")}
                value={Math.round(motif.area)}
                margin="normal"
                InputProps={{
                  readOnly: true,
                  endAdornment: <InputAdornment position="end">cm²</InputAdornment>,
                }}
                disabled
              />
            </Grid>
          </Grid>
          {motif.approved && (
            <Alert severity="warning" variant="filled" sx={{ mt: 2 }}>
              {t("Dashboard.Motif.EditWarning")}
            </Alert>
          )}

          <Button variant="contained" color="primary" onClick={handleUpdate} sx={{ mt: 2, mb: 1 }}>
            {t("Dashboard.Motif.UpdateMotif")}
          </Button>
        </Grid>
      </Grid>

      {error && (
        <Alert severity="error" variant="outlined" label sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Dialog
        open={openDelDia}
        onClose={diaDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Dashboard.Motif.DelMotifConfirm")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={diaDeleteClose} color="primary">
            {t("Dashboard.Motif.Cancel")}
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            {t("Dashboard.Motif.Confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openImgDia} onClose={diaImgClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t("Dashboard.Motif.NewImg")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("Dashboard.Motif.NewImgText")}</DialogContentText>
          <input
            accept=".jpg,.jpeg,.png,.svg,.pdf,.ai"
            style={{ display: "none" }}
            id="upload-button-file"
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="upload-button-file" style={{ cursor: "pointer", display: "block" }}>
            <Button variant="contained" color="primary" component="span">
              {t("Dashboard.Motif.NewImgSel")}
            </Button>
          </label>
          {imagePreviewUrl && (
            <Box mt={2} textAlign="center">
              <img
                src={imagePreviewUrl}
                alt="New"
                style={{
                  maxWidth: "100%",
                  maxHeight: "50vh",
                  objectFit: "contain",
                  borderRadius: theme.shape.borderRadius,
                  backgroundColor: "gray",
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={diaImgClose} color="primary">
            {t("Dashboard.Motif.Cancel")}
          </Button>
          <Button onClick={handleImageUploadConfirm} color="primary">
            {t("Dashboard.Motif.Upload")}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default EditMotif;
