import { useState, useCallback } from "react";
import { API_URL } from "../apiConfig";
import secureFetch from "./secureFetch";
import { useAuth } from "./useAuth";

const baseUrl = `${API_URL}/user`;

export function useUser() {
  const { refreshToken } = useAuth();
  const [user, setUser] = useState();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch user by ID
  const getUserByID = useCallback(
    async (userId) => {
      setLoading(true);
      setError(null);

      try {
        const response = await secureFetch(`${baseUrl}/${userId}`, {}, refreshToken);
        setUser(response.data);
      } catch (error) {
        setError(error.message);
        setUser(null);
      } finally {
        setLoading(false);
      }
    },
    [refreshToken]
  );

  // Fetch all users
  const getAllUsers = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await secureFetch(`${baseUrl}/`, {}, refreshToken);
      setUsers(response.data);
    } catch (error) {
      setError(error.message);
      setUsers([]);
    } finally {
      setLoading(false);
    }
  }, [refreshToken]);

  // Update user profile by ID
  const updateUserProfile = useCallback(
    async (userId, updatedData) => {
      setLoading(true);
      setError(null);
      console.log(updatedData);
      try {
        const response = await secureFetch(
          `${baseUrl}/${userId}`,
          {
            method: "PUT",
            data: updatedData,
          },
          refreshToken
        );

        setUser(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [refreshToken]
  );

  // Remove a user by ID (and associated motifs/orders)
  const removeUser = useCallback(
    async (userId) => {
      setLoading(true);
      setError(null);

      try {
        await secureFetch(
          `${baseUrl}/${userId}`,
          {
            method: "DELETE",
          },
          refreshToken
        );

        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [refreshToken]
  );

  return {
    user,
    users,
    loading,
    error,
    getUserByID,
    getAllUsers,
    updateUserProfile,
    removeUser,
  };
}
