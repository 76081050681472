import React, { createContext, useReducer, useEffect, useState } from "react";
import useCart from "../hooks/useCart";

const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case "SetCart":
      return action.cart; // Set the initial cart state
    case "Add": {
      // Check if the motif already exists in the cart
      const existingItem = state.find((item) => item.id === action.motif.id);
      if (existingItem) {
        // Update the quantity if the motif is already in the cart
        return state.map((item) =>
          item.id === action.motif.id
            ? { ...item, quantity: action.quantity }
            : item
        );
      }
      // If it's a new item, add it to the cart with the quantity
      return [...state, { ...action.motif, quantity: action.quantity }];
    }
    case "Remove":
      return state.filter((item) => item.id !== action.id);
    case "Clear":
      return [];
    default:
      return state;
  }
};

const CartProvider = ({ children }) => {
  const {
    cart: initialCart,
    addToCart,
    updateCart,
    removeFromCart,
    clearCart,
  } = useCart(); // Hook to interact with backend
  const [cart, dispatch] = useReducer(cartReducer, []);

  const normalizeCart = (cart) => {
    return cart.map((item) => ({
      ...item,
      ...item.motif, // Spread motif details into the main item object
      motif: undefined, // Remove the nested motif object if necessary
    }));
  };

  // Initialize the cart by fetching from backend whenever initialCart updates
  useEffect(() => {
    if (initialCart && initialCart.length > 0) {
      dispatch({ type: "SetCart", cart: normalizeCart(initialCart) });
    }
  }, [initialCart]);

  // Add item to cart, both in frontend state and backend
  const handleAddToCart = async (motif, quantity) => {
    try {
      await addToCart(motif.id, quantity); // Backend call
      dispatch({ type: "Add", motif, quantity });
    } catch (error) {
      console.error("Failed to add item to cart", error);
    }
  };

  // Remove item from cart, both in frontend state and backend

  const handleRemoveFromCart = async (id) => {
    try {
      await removeFromCart(id); // Backend call
      dispatch({ type: "Remove", id });
    } catch (error) {
      console.error("Failed to remove item from cart", error);
    }
  };

  // Clear cart, both in frontend state and backend
  const handleClearCart = async () => {
    try {
      await clearCart(); // Backend call
      dispatch({ type: "Clear" });
    } catch (error) {
      console.error("Failed to clear cart", error);
    }
  };

  return (
    <CartContext.Provider
      value={{ cart, handleAddToCart, handleRemoveFromCart, handleClearCart }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
